<template>
    <div>
        <b-overlay :show="loading" variant="white" rounded="sm">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="card-title titulo-tarjetas">
                            Galeria Imágenes
                        </div>
                    </div>
                     <div class="alert-danger p-3" role="alert" v-if="!mostrarMensajeImagenes">
                                    No se han ingresado imagenes para este lugar de Instalación
                                </div>
                    <div class="popup-gallery d-flex flex-wrap" v-if="mostrarMensajeImagenes">
                        <div
                            v-for="(src, index) in imagenes"
                            :key="index"
                            class="float-left imagen-galeria"
                            @click="() => showImg(index)"
                        >
                            <img :src="src" width="250" height="250" />
                        </div>
                        <vue-easy-lightbox
                            :visible="visible"
                            :imgs="imagenes"
                            :index="index"
                            @hide="handleHide"
                        ></vue-easy-lightbox>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import {
    galeriaImagenesMethods,
    galeriaImagenesComputed,
} from "@/state/helpers";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import VueEasyLightbox from "vue-easy-lightbox";
export default {
    components: {
        VueEasyLightbox,

    },
    data() {
        return {
            hooperSettings: {
                centerMode: false,
                breakpoints: {
                    500: {
                        centerMode: true,
                        itemsToShow: 1,
                    },
                    800: {
                        centerMode: true,
                        itemsToShow: 1,
                    },
                    1000: {
                        //  centerMode: true,
                        itemsToShow: 2,
                        pagination: "fraction",
                    },
                    1600: {
                        //  centerMode: true,
                        itemsToShow: 3,
                        pagination: "fraction",
                    },
                    2100: {
                        itemsToShow: 4,
                        pagination: "fraction",
                    },
                },
            },
            loading: false,
            imagenes: [],
            lugarInstalacion: {
                codigo_lugar_instalacion: this.$route.params
                    .codigoLugarInstalacion,
                codigo_proyecto: this.$route.params.codigoProyecto,
            },
            codigoProyecto: {},
            codigoLugarInstalacion: {},
            visible: false,
            singleImage: false,
            visibleCaption: false,
            index: 0, // default: 0
            proyectoSeleccionado: {},
            mostrarMensajeImagenes:false
        };
    },
    mounted() {
        if (this.proyectoUsuario == null) {
            this.obtenerProyectoUsuario();
        } else {
            this.proyectoSeleccionado = this.proyectoUsuario;
        }
        this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
        this.codigoProyecto = this.$route.params.codigoProyecto;
        this.obtenerImagenesGaleria();
    },
    methods: {
        ...galeriaImagenesMethods,
        ...proyectoMethods,
        obtenerProyectoUsuario() {
            this.obtenerProyecto(this.$route.params.codigoProyecto)
                .then((res) => {
                    this.proyectoSeleccionado = res.body;
                })
                .catch((error) => {});
        },
        obtenerImagenesGaleria() {
            this.loading = true;
            this.obtenerImagenes({
                codigoLugarInstalacion: this.codigoLugarInstalacion,
                codigoProyecto: this.codigoProyecto,
            })
                .then((res) => {
                     this.mostrarMensajeImagenes = true
                    if(res.body.length == 0 ){
                        this.mostrarMensajeImagenes = false
                    }
                    this.imagenes = res.body;
                   
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },

        showImg(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
        showCaptiomImg(index) {
            this.index = index;
            this.visibleCaption = true;
        },
        handleCaptionHide() {
            this.visibleCaption = false;
        },
    },
    computed: {
        ...proyectoComputed,
    },
};
</script>

<style scoped>
.imagen-galeria {
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>
