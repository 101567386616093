<template>
    <div>
        <b-overlay :show="loading" variant="white" rounded="sm">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <b-alert
                                :show="segundosMensajeActualizacion"
                                dismissible
                                :variant="mensaje.variant"
                                @dismissed="segundosMensajeActualizacion = 0"
                                @dismiss-count-down="
                                    actualizarSegundosMensajeActualizacion
                                "
                            >
                                {{ mensaje.texto }}
                            </b-alert>
                            <div class="d-flex">
                                <div
                                    class="card-title col-auto me-auto titulo-tarjetas"
                                >
                                    Bitácora
                                </div>
                                <Menu :lugarInstalacion="lugarInstalacion" />
                            </div>
                            <h5 class="card-title-desc mt-30-576 mt-30-1024px">
                                {{ proyectoSeleccionado.nombre_proyecto }} -
                                {{ lugarInstalacion.codigo_proyecto }} -
                                {{ lugarInstalacion.codigo_lugar_instalacion }}
                            </h5>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label for="fechaInforme">Fecha</label>
                                    <VueDatePicker
                                        v-model="fechaSeleccionada"
                                        @input="buscar()"
                                        placeholder="Seleccionar Fecha"
                                    />

                                    <div
                                        v-if="
                                            submitted &&
                                                !$v.form.fechaInicio.required
                                        "
                                        class="invalid-feedback"
                                    >
                                        El campo es obligatorio.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <InformeDiario
                    :proyectoAvanceDiarios="proyectoAvanceDiarios"
                    v-if="renderComponent"
                />
            </div>

            <div class="row mt-4" v-if="renderComponent">
                <Galaria />
            </div>

            <div class="row"  v-if="renderComponent">
                <Adjuntos :fechaSubida="fechaSeleccionada"/>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { tipoProyectoMethods, tipoProyectoComputed } from "@/state/helpers";
import { actividadMethods, actividadComputed } from "@/state/helpers";
import {
    actividadProyectoMethods,
    actividadProyectoComputed,
} from "@/state/helpers";
import {
    actividadProyectoAvanceDiarioMethods,
    actividadProyectoAvanceDiarioComputed,
} from "@/state/helpers";
import {
    actividadProyectoPersonalObraHorasTrabajadasMethods,
    actividadProyectoPersonalObraHorasTrabajadasComputed,
} from "@/state/helpers";
import {
    actividadTipoProyectoConfiguracionMethods,
    actividadTipoProyectoConfiguracionComputed,
} from "@/state/helpers";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
// import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import Menu from "@/components/widgets/menu/MenuWidget";
import InformeDiario from "./informe-diario-componente";
import Galaria from "./galeria-componente.vue";
// FIXME
// import Adjuntos from "@/components/proyectos/lugarInstalacion/LugarDocumentos.vue";
import Adjuntos from "./documentos-componente.vue";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    components: {
        // Multiselect,
        Menu,
        InformeDiario,
        Galaria,
        Adjuntos
    },

    data() {
        return {
            loading: false,
            loadingForm: false,
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            lugarInstalacion: {},

            codigoProyecto: null,
            codigoLugarInstalacion: null,
            submitted: false,
            mostrarModal: false,

            fechaInicioValidar: null,
            fechaTerminoValidar: null,

            rolModuloPermiso: {},

            fechaSeleccionada: null,
            proyectoAvanceDiarios: {},
            proyectoAvanceDiariosCargando: false,
            proyectoSeleccionado: {},

            renderComponent: true,
        };
    },

    mounted() {
        if (this.proyectoUsuario == null) {
            this.obtenerProyectoUsuario();
        } else {
            this.proyectoSeleccionado = this.proyectoUsuario;
        }
        this.codigoProyecto = this.$route.params.codigoProyecto;
        this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
        this.lugarInstalacion = {
            codigo_lugar_instalacion: this.codigoLugarInstalacion,
            codigo_proyecto: this.codigoProyecto,
        };

        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
        this.fechaSeleccionada = moment().format('YYYY-MM-DD')
        this.obtenerAvanceDiario();
    },
    methods: {
        ...actividadProyectoPersonalObraHorasTrabajadasMethods,
        ...actividadProyectoAvanceDiarioMethods,
        ...actividadProyectoMethods,
        ...proyectoMethods,
        ...actividadMethods,
        ...actividadTipoProyectoConfiguracionMethods,
   
        buscar() {
            this.obtenerAvanceDiario();
        },
        obtenerAvanceDiario() {
            this.loading = true;

            this.obtenerActividadProyectoAvanceDiariosFecha({
                codigoLugarInstalacion: this.codigoLugarInstalacion,
                codigoProyecto: this.codigoProyecto,
                fecha: this.fechaSeleccionada,
            })
                .then((res) => {
                    this.proyectoAvanceDiarios = res.body;
                    this.loading = false;
                    this.forceRerender();
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        obtenerDocumentosLocal() {
            this.documentosCargando = true;
            this.obtenerDocumentos({
                codigoLugarInstalacion: this.codigoLugarInstalacion,
                codigoProyecto: this.codigoProyecto,
            })
                .then((res) => {
                    if(this.user.codigo_rol == 'CLIENTE'){
                        this.documentos = res.body.filter(
                            (val) => val.privado == false
                        )
                    }else{
                        this.documentos = res.body;
                    }
                    this.totalRows = this.documentos.length
                    this.documentosCargando = false;
                    this.$v.form.nombreDocumento.$model = {
                        nombre_documento_id: this.documentoSeleccionado
                            .nombre_documento_id,
                        nombre_documento: this.documentoSeleccionado
                            .nombre_documento,
                    };
                })
                .catch((error) => {
                    this.documentosCargando = false;
                });
        },
        forceRerender() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },
        obtenerProyectoUsuario() {
            this.obtenerProyecto(this.$route.params.codigoProyecto)
                .then((res) => {
                    this.proyectoSeleccionado = res.body;
                })
                .catch((error) => {});
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },

        irLugarinstalacion(proyecto) {
            this.seleccionarProyecto(proyecto[0]);
            this.$router.push({
                name: "lugaresInstalacion",
                params: { codigoProyecto: proyecto[0].codigo_proyecto },
            });
        },
    },
    computed: {
        ...proyectoComputed,
        ...authUsuarioComputed,
    },
};
</script>

<style>
.crearActividadesProyecto {
    margin-right: 10px;
}
</style>
