<template>
    <v-container>
        <PageHeader :title="title" :items="items" />
        <BitacoraComponente />
    </v-container>
</template>
<script>
//import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import BitacoraComponente from "./bitacora-componente.vue";
export default {
    page: {
        title: "Bitácora",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        //Layout,
        PageHeader,
        BitacoraComponente
    },
    data() {
        return {
            title: "Bitácora",
            items: [
                {
                    text: "Proyectos",
                    href: `/proyectos/proyectos`,
                },
                {
                    text: "Lugares Instalación",
                    href: `/proyectos/lugaresInstalacion/${this.$route.params.codigoProyecto}`,
                },
                {
                    text: "Bitácora",
                    active: true,
                },
            ],
        };
    },
};
</script>