<template>
    <div>
        <div class="card" v-if="proyectoAvanceDiarios.observacion != null">
            <div class="card-body">
                <label for="fechaInforme" class="">Observación Día</label>
                <div>
                    {{ proyectoAvanceDiarios.observacion }}
                </div>
            </div>
        </div>
        <div
            class="tarjeta-actividad"
            v-for="(actividad, i) in proyectoAvanceDiarios.actividades"
            :key="i"
        >
            <div class="fs-4 actividad mb-3 text-primary">
                {{ actividad.actividad }}
            </div>
            <div class="avance row">
                <div class="col-10">
                    Avance
                </div>
                <div class="col-2 text-center">
                    <span
                        class="badge badge-pill badge-soft-success font-size-18"
                    >
                        {{
                            Math.round(
                                (actividad.acumulado_total /
                                    actividad.cantidad_total) *
                                    100
                            )
                        }}%</span
                    >
                </div>
            </div>

            <div class="datos-actividad">
                <div class="avanceHoy mt-3 row">
                    <div class="col-10">
                        Avance Hoy
                    </div>
                    <div class="col-2 text-center">
                        <span
                            class="badge badge-pill badge-soft-primary font-size-13"
                            >{{ actividad.acumulado_hoy }}</span
                        >
                    </div>
                </div>
                <div class="unidad mt-3 row">
                    <div class="col-10">
                        Unidad
                    </div>
                    <div class="col-2 text-center">
                        <span
                            class="badge badge-pill badge-soft-primary font-size-13"
                            >{{ actividad.nombre_unidad }}</span
                        >
                    </div>
                </div>
                <div class="comentario">
                    <label for="fechaInforme" class="mt-4 fw-bold"
                        >Observación</label
                    >
                    <div>
                        {{ actividad.observacion }}
                    </div>
                </div>
            </div>

            <label for="fechaInforme" class="mt-4 fw-bold">Funcionarios</label>
            <div class="funcionarios">
                <div
                    class="funcionario mt-2"
                    v-for="(personal, indice) in actividad.personal"
                    :key="indice"
                >
                    <div class="row">
                        <div class="col-10">
                            {{ personal.nombre_personal_obra }}
                        </div>
                        <div class="col-2 text-center fw-bold">
                            {{ personal.horas_trabajadas }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { tipoProyectoMethods, tipoProyectoComputed } from "@/state/helpers";
import { actividadMethods, actividadComputed } from "@/state/helpers";
import {
    actividadProyectoMethods,
    actividadProyectoComputed,
} from "@/state/helpers";
import {
    actividadProyectoAvanceDiarioMethods,
    actividadProyectoAvanceDiarioComputed,
} from "@/state/helpers";
import {
    actividadProyectoPersonalObraHorasTrabajadasMethods,
    actividadProyectoPersonalObraHorasTrabajadasComputed,
} from "@/state/helpers";
import {
    actividadTipoProyectoConfiguracionMethods,
    actividadTipoProyectoConfiguracionComputed,
} from "@/state/helpers";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    components: {},
    props: {
        proyectoAvanceDiarios: Object,
    },
    data() {
        return {
            lugarInstalacion: {},
            codigoProyecto: null,
            codigoLugarInstalacion: null,
            rolModuloPermiso: {},
        };
    },
    mounted() {
        this.codigoProyecto = this.$route.params.codigoProyecto;
        this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
        this.lugarInstalacion = {
            codigo_lugar_instalacion: this.codigoLugarInstalacion,
            codigo_proyecto: this.codigoProyecto,
        };
        // this.obtenerPersonalObraProyecto();
        // this.obtenerActividadesProyectoLocal();

        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
    },
    methods: {
        ...actividadProyectoPersonalObraHorasTrabajadasMethods,
        ...actividadProyectoAvanceDiarioMethods,
        ...actividadProyectoMethods,
        ...proyectoMethods,
        ...actividadMethods,
        ...actividadTipoProyectoConfiguracionMethods,
    },
    computed: {
        ...proyectoComputed,
        ...authUsuarioComputed,
    },
};
</script>
